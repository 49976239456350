import './App.css';
import {Container, Navbar, Nav, Row, Col} from 'react-bootstrap';

function App() {
  return (
    <>
     <div className="banner">
      <h6>Designed Like This</h6>
      <h4>Photo Retouching</h4>
    </div>

    <br></br>
<br></br>

    <Container>
      <Row className ="align-items-center">
        <Col xs={12} xl={6}>
          <div className='heroText align-middle'>
           <h1 style={{display:"contents", verticalAlign: "middle"}}>Hold onto memories.</h1>
         </div>
        </Col>
        <Col xs={12} xl={6}>
         <div className="heroShot">
           <div className="heroImg">
            <img src={require("./BNA2.png")} alt="BeforeandAfter" className='img-fluid shadow-4' style={{width: "600px", borderRadius: "20px"}}/>
           </div>
        </div>
        </Col>
      </Row>
    </Container>

<br></br>
<br></br>
<br></br>

    <Container>
      <Row>
                <Col xs={12} xl={6}>
         <div className="heroShot">
           <div className="heroImg">
            <img src={require("./BNA1.png")} alt="BeforeandAfter" className='img-fluid shadow-4' style={{width: "600px", borderRadius: "20px"}}/>
           </div>
        </div>
        </Col>
        <Col xs={12} xl={6}>
          <div className='infoText'>
          <h3>Professional Photo Retouching and Recolouring</h3>
          <ul></ul>
           <ul>- Fix damaged photos.</ul>
           <ul>- Remove marks, stains and scratches.</ul>
           <ul>- Add colour back to faded images.</ul>
           <ul>- Fast turnaround.</ul>
           <ul>- No hidden fees. Only pay if you're happy with the work.</ul>
         </div>
        </Col>
      </Row> 
    </Container>

    
<br></br>
<br></br>
<br></br>


<Container>
      <Row>
        <Col xs={12} xl={6}>
          <div className='pricingText'>
          <h2>Packages</h2>
          <br></br>
          <h4>Photo Retouching: $50 per image</h4>
          <ul></ul>
          <p>Heal old photos back to great condition. Get damage, scratches and dust professionally removed from your images.</p>
          <br></br>
          <h4>Photo Retouching + Recolouring: $90 per image</h4>
          <p>Take your photo further with recolouring. You'll get everything from the Photo Retouching package, plus manual airbrushing of colours to bring your image back to life.</p>
         </div>
        </Col>
        <Col xs={12} xl={6}>
         <div className="heroShot">
           <div className="heroImg">
            <img src={require("./BNA3.png")} alt="BeforeandAfter" className='img-fluid shadow-4' style={{width: "600px", borderRadius: "20px"}}/>
           </div>
        </div>
        </Col>
      </Row>
    </Container>

<br></br>
<br></br>
<br></br>
    
    <Container>
    <div className="contactUs">
      <h1>Contact Us</h1>
      <br></br>
      <div className="row">
      <div className="col-lg-3">
      <div className="contactPhoto">
      <img src={require("./vintageprofilepic.png")} alt="Profile" style={{width: "200px", borderRadius: "20px"}}/>
      </div>
      </div>
      <div className="col-lg-9">
      <div className="contactInfo">
      <p>Hi, I'm Andrew from Designed Like This.</p>
      <p> Designed Like This is an Australian service which does not outsource its work. If you're after tailored, professional results we're the service for you.</p>
      <p>Send us a message and your photos to andrew.johnston@designedlikethis.com</p>
      </div>
      </div>
      </div>
      </div>
      </Container>

    <div className="footer"> 
      <p>© 2024 Andrew Johnston</p>
      </div>
    </>
    );
}

export default App;
